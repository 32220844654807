import React from "react";
import kreatechLogo from "../logo.svg";
import kreatech_logo from "../img/kr.png";

const NavbarLogo = ({ onClick, link = false }) => {
  const inner = (
    <div className="flex items-center cursor-pointer">
      <img
        alt="Logo"
        src={kreatech_logo}
        onClick={onClick}
        className="w-12 h-12 hover:scale-110 transition-transform duration-300"
      />
      <p className="text-2xl font-medium">Kreatech</p>
    </div>
  );

  if (link) return <a href="/">{inner}</a>;
  return <div>{inner}</div>;
};

export default NavbarLogo;
