import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import ArrowLeftIcon from "@mui/icons-material/ArrowBack";
import ArrowRightIcon from "@mui/icons-material/ArrowForward";
import { arrowStyle } from "../../components/StyleStandardize";
import comma_left from "../../img/comma_left.png";
import comma_right from "../../img/comma_right.png";
import { comments, commentsOwner } from "../../components/ThingsStandardize";
import ReadMore from "../../components/ReadMore";
const AboutThird = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalComments = comments.length;

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + totalComments) % totalComments
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalComments);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex]);

  const owner = commentsOwner[currentIndex];
  return (
    <div className="flex flex-col gap-10 md:w-4/5 m-auto">
      <Header
        text="Why customers love"
        textB="working with us"
        blue
        borderMid
      />
      <div className="flex flex-row justify-between px-1">
        <button onClick={prevSlide} className={`${arrowStyle}`}>
          <ArrowLeftIcon />
        </button>
        <div className="m-auto flex flex-col gap-6 md:w-3/5">
          {/* Comment section */}
          <div key={currentIndex} className="m-auto flex gap-1">
            <img src={comma_left} alt="'" className="mb-auto" />
            <p className="leading-loose py-3">
              {comments[currentIndex].comment}
            </p>
            <img src={comma_right} alt="'" className="mt-auto" />
          </div>

          {/* For large and medium screens */}
          <div className="hidden md:flex gap-5">
            {commentsOwner.map((owner, index) => {
              const opacity = index === currentIndex ? 1 : 0.2;
              return (
                <div
                  key={index}
                  style={{ opacity }}
                  className="flex flex-col items-center"
                >
                  <img
                    src={owner.src}
                    alt={owner.alt}
                    className={`w-20 h-20 rounded-full`}
                  />
                  <img
                    src={owner.rating}
                    alt={owner.ratingAlt}
                    className="w-20"
                  />
                  <p
                    className={`font-semibold ${
                      index === currentIndex ? " text-[#57007B]" : ""
                    }`}
                  >
                    {owner.name}
                  </p>
                  <p>{owner.position}</p>
                </div>
              );
            })}
          </div>

          {/* For small size screens */}
          <div className="block md:hidden">
            <div
              style={{ opacity: 1 }}
              className="flex flex-col items-center m-auto"
            >
              <img
                src={owner.src}
                alt={owner.alt}
                className="w-20 rounded-full"
              />
              <img src={owner.rating} alt={owner.ratingAlt} className="w-20" />
              <p className="font-semibold text-[#57007B]">{owner.name}</p>
              <p>{owner.position}</p>
            </div>
          </div>
        </div>
        <button onClick={nextSlide} className={`${arrowStyle}`}>
          <ArrowRightIcon />
        </button>
      </div>
      <ReadMore routeTo="/AboutUs" text="Know More About Us" />
    </div>
  );
};

export default AboutThird;
