import React from "react";
import IndustryFirst from "../sections/IndustryFirst";
import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";

const IndustryPage = () => {
  return (
    <>
    <Helmet>
    <meta charSet="utf-8" />
      <title>Kreatech serves in construction, Courier, Real Estate & Garments.</title>
      <meta
      name="description"
      content="Kreatech: Powering Success in Construction, Courier, and Restaurant Website Redesign, Real Estate & Garments Industries with Expert Solutions!"
       />
    </Helmet>
    <div>
      <Navbar />
      <div className="mt-14">
      <IndustryFirst />
      </div>
    </div>
    </>
  );
};

export default IndustryPage;
