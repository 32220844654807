import React from "react";
import AboutFirst from "./sections/AboutFirst";
import AboutSecond from "./sections/AboutSecond";
import AboutThird from "./sections/AboutThird";
const AboutUs = () => {
  return (
    <div
      id="aboutUs"
      className="pt-16 min-w-full flex flex-col gap-y-4 md:gap-y-8"
    >
      {/* Leading companies trust us */}
      {/* <AboutFirst /> */}
      {/* Happy Clients */}
      <AboutSecond />
      {/* Why custiomers love working with us */}
      <AboutThird />
    </div>
  );
};

export default AboutUs;
