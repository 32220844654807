import "./App.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Main from "./sections/Main";
import IndustryPage from "./Industry/IndustryPage/IndustryPage";
import ServicesPage from "./Services/ServicesPage/ServicesPage";
import AboutUsPage from "./About/AboutUsPage/AboutUsPage";
import CareerPage from "./Career/CareerPage/CareerPage";
import BlogPage from "./Blog/BlogPage/BlogPage";
import BlogPostFirst from "./Blog/BlogPage/BlogPostFirst";
import BlogPostSecond from "./Blog/BlogPage/BlogPostSecond";
import BlogPostThird from "./Blog/BlogPage/BlogPostThird";
import Blog1 from "./Blog/Blog1";
import CaseStudiesPage from "./CaseStudies/CaseStudiesPage/CaseStudiesPage";
import Contact from "./sections/Contact";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
 },[pathname]);
  return (
    <div className="App ">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/Services" element={<ServicesPage />} />
        <Route path="/AboutUs" element={<AboutUsPage />} />
        <Route path="/Career" element={<CareerPage />} />
        <Route path="/Industry" element={<IndustryPage />} />
        <Route path="/Blog" element={<BlogPage />} />
        <Route path="/5-tips-for-optimizing-website" element={<BlogPostFirst />} />
        <Route path="/erp-software-with-kreatech" element={<BlogPostSecond />} />
        <Route path="/kreatech-erp-for-business" element={<BlogPostThird />} />
        <Route path="/CaseStudies" element={<CaseStudiesPage />} />
      </Routes>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
