import React from "react";
import sumon_logo from "../../img/sumon_logo.png";
import arians_logo from "../../img/arians_logo.png";
import scs_logo from "../../img/scs_logo.png";
import dTech_logo from "../../img/dTech_logo.png";
import dholdings_logo from "../../img/d_holdings.png";
import bts_logo from "../../img/bts_logo.png";
import polestar_logo from "../../img/polestar-logo.png";
import homes_excellence_logo from "../../img/homes_excellence_logo.png";
import oriental_logo from "../../img/oriental_logo.png";
import "./AboutSecond.css";
import HalfCircle from "../../components/HalfCircle";
import Header from "../../components/Header";
import { useState, useEffect } from "react";

const AboutSecond = () => {
  return (
    <div>
      <Header
        text="Happy Clients"
        textB="We are working with"
        yellow
        placement="ml-[10%]"
      />
      <div>
        <div className="ml-[10%]">
          <HalfCircle up />
        </div>
        {/* Slider of logos */}
        <div className="logos">
          <div className="logos-slide">
            <img src={sumon_logo} alt="M/S Sumon Enetrprise Ltd." />

            <img src={arians_logo} alt="Arian Taders" />

            <img src={scs_logo} alt="SCS Express" className="" />

            <img src={dTech_logo} alt="DTech Corp. Ltd." />

            <img src={homes_excellence_logo} alt="Homes of Excellence" />

            <img src={oriental_logo} alt="Oriental" />

            <img src={dholdings_logo} alt="D Holdings" />

            <img src={bts_logo} alt="BTS" />

            <img src={polestar_logo} alt="Pole Star Group" />

            <img src={sumon_logo} alt="M/S Sumon Enetrprise Ltd." />

            <img src={arians_logo} alt="Arian Taders" />

            <img src={scs_logo} alt="SCS Express" className="" />

            <img src={dTech_logo} alt="DTech Corp. Ltd." />

            <img src={homes_excellence_logo} alt="Homes of Excellence" />

            <img src={oriental_logo} alt="Oriental" />

            <img src={dholdings_logo} alt="D Holdings" />

            <img src={bts_logo} alt="BTS" />

            <img src={polestar_logo} alt="Pole Star Group" />
            
          </div>
          {/* <div className="logos-slide">
            <img src={sumon_logo} alt="M/S Sumon Enetrprise Ltd." />

            <img src={arians_logo} alt="Arian Taders" />

            <img src={scs_logo} alt="SCS Express" />

            <img src={dTech_logo} alt="DTech Corp. Ltd." />

            <img src={homes_excellence_logo} alt="Homes of Excellence" />

            <img src={oriental_logo} alt="Oriental" />

            <img src={dholdings_logo} alt="D Holdings" />
          </div> */}
        </div>
        {/* Slider of logos */}
        <div className="ml-[80%]">
          <HalfCircle down />
        </div>
      </div>
    </div>
  );
};

export default AboutSecond;
