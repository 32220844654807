import React from "react";
import Helmet from "react-helmet";
import { Slide } from "react-reveal";
import Navbar from "../../components/Navbar";
import arian_logo from "../../img/bg_arian.png";
import ens_logo from "../../img/bg_sumon.png";
import scs_logo from "../../img/bg_scs.png";
import arain_image from "../../img/caseStudiesArian2.png";
import ens_image from "../../img/caseStudiesENS.png";
import scs_image from "../../img/caseStudiesSCS.png";

import { caseStudiesContents } from "../../components/ThingsStandardize";

const CaseStudiesPage = () => {
  const text = "OUR WORKS";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kreatech: Mastered in Web Development & SEO Marketing Solution</title>
        <meta
          name="description"
          content="Kreatech excelled in Accounts ERP solutions for SCS Express, while Arian Traders witnessed Website Development & SEO Enhancement, amplifying digital growth."
        />
      </Helmet>
      <div>
        <Navbar />
        
        <div className="w-11/12 mx-auto mt-[150px]">
          <div className="w-11/12 mx-auto flex flex-row justify-start items-start">
            {text.split("").map((char, index) => (
              <div key={index} className={`text-xl md:text-4xl pr-10`}>
                {char}
              </div>
            ))}
          </div>

          {/* Works */}
          <div
          style={{
            backgroundImage: `url(${arian_logo})`,
          }}
          className="bg-no-repeat bg-center">
          <div className="w-11/12 mt-20 mx-auto flex flex-col justify-start items-start">
            <div className="flex flex-row justify-start items-start gap-2">
              {/* <img src={arian_logo} className="w-[5%]" /> */}
              <p className="text-[40px] font-semibold">Website Development and SEO Enhancement for Arian Traders</p>
            </div>
            <Slide left duration={1400}>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-5">
              
              <p className="text-left text-[30px]">Empower our online presence with Arian Traders! Our Website Development and SEO Enhancement offer a dynamic digital edge. Customized websites, intuitive navigation, and strategic SEO elevate visibility and success. Transform our brand's online impact with Arian Traders.</p>
              <img src={arain_image} />
            </div>
            </Slide>
          </div>
          </div>

          <div
          style={{
            backgroundImage: `url(${ens_logo})`,
          }}
          className="bg-no-repeat bg-center">
          <div className="w-11/12 mt-20 mx-auto flex flex-col justify-start items-start">
            <div className="flex flex-row justify-start items-start gap-2">
              {/* <img src={arian_logo} className="w-[5%]" /> */}
              <p className="text-[40px] font-semibold">Web Hosting & Scheduled Maintenance for Homes by Ens, Canada</p>
            </div>
            <Slide right duration={1400}>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-5">
            <img src={ens_image} />
              <p className="text-right text-[30px]">Born out of a vision, a single-minded objective that puts service before anything else, Swift Clearance and Forwarding Corp. surging forth to deliver the best services in the shipping and logistics scenario. Its meteoric rise stems out of a solid foundation. The management boasts of over 20 years of rich and varied experience in the shipping and freight forwarding industry.</p>
              
            </div>
            </Slide>
          </div>
          </div>

          <div
          style={{
            backgroundImage: `url(${scs_logo})`,
          }}
          className="bg-no-repeat bg-center">
          <div className="w-11/12 mt-20 mx-auto flex flex-col justify-start items-start">
            <div className="flex flex-row justify-start items-start gap-2">
              {/* <img src={arian_logo} className="w-[5%]" /> */}
              <p className="text-[40px] font-semibold">Accounts ERP Development for SCS Express</p>
            </div>
            <Slide left duration={1400}>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-5">
              
              <p className="text-left text-[30px]">SCS Express excels in Accounts ERP Development, offering tailored solutions for seamless financial management. It epitomizes efficiency and precision with integrated modules, automation, and real-time insights. Security, scalability, and continuous improvement define their commitment.</p>
              <img src={scs_image} />
            </div>
            </Slide>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesPage;
