import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { blogContents } from "../../components/ThingsStandardize";
import ReadMore from "../../components/ReadMore";
import ArrowLeftIcon from "@mui/icons-material/ArrowBack";
import ArrowRightIcon from "@mui/icons-material/ArrowForward";
import { arrowStyle } from "../../components/StyleStandardize";
import { Link } from 'react-router-dom';
import BlogPage from "../BlogPage/BlogPage";

const BlogFirst = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalBlogs = blogContents.length;
  const slidesToShowLarge = 2; //4
  const slidesToShowMedium = 2; //3
  const slidesToShowSmall = 1;

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalBlogs) % totalBlogs);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalBlogs);
  };

  const getVisibleCardsLarge = () => {
    const visibleCards = [];
    for (let i = 0; i < slidesToShowLarge; i++) {
      let opacity = 1;
      if (i === 0 || i === slidesToShowLarge - 1) {
        opacity = 0.1;
      } else {
        opacity = 1;
      }

      visibleCards.push({
        ...blogContents[(currentIndex + i) % totalBlogs],
        opacity: opacity,
      });
    }
    return visibleCards;
  };

  const getVisibleCardsMd = () => {
    const visibleCards = [];
    for (let i = 0; i < slidesToShowMedium; i++) {
      visibleCards.push(blogContents[(currentIndex + i) % totalBlogs]);
    }
    return visibleCards;
  };

  const getVisibleCardsSm = () => {
    const visibleCards = [];
    for (let i = 0; i < slidesToShowSmall; i++) {
      visibleCards.push(blogContents[(currentIndex + i) % totalBlogs]);
    }
    return visibleCards;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex]);

  return (
    <div className="flex flex-col gap-10 py-10  border-t-2 border-[#E3EEF8]">
      <Header text="Featured" textB="Resources" blue borderMid />

      <div className="hidden lg:flex flex-row flex-wrap gap-3 text-left m-auto justify-around">
        {getVisibleCardsLarge().map((el, i) => {
          return (
            <div key={i} className="flex flex-col gap-3 w-60">
              <img src={el.src} alt={el.alt} className="" />
              <p>{el.text}</p>
              
              <ReadMore description={el.description} key={i} routeTo="/Blog" text="Read more" /> 
             
            </div>
          );
        })}
      </div>

      <div className="m-auto hidden md:flex lg:hidden gap-3">
        {getVisibleCardsMd().map((el, i) => {
          return (
            <div key={i} className="flex flex-col gap-3 w-60">
              <img src={el.src} alt={el.alt} className="" />
              <p>{el.text}</p>
              
              <ReadMore description={el.description} routeTo="/Blog" text="Read more" />
              
            </div>
          );
        })}
      </div>

      <div className="block m-auto md:hidden">
        {getVisibleCardsSm().map((el, i) => {
          return (
            <div key={i} className="flex flex-col gap-3 w-60">
              <img src={el.src} alt={el.alt} className="" />
              <p>{el.text}</p>
              
              <ReadMore description={el.description} routeTo="/Blog" text="Read more" />
              
            </div>
          );
        })}
      </div>

      {/* Left and Right arrow */}
      <div className="m-auto flex gap-6">
        <button onClick={prevSlide} className={`${arrowStyle}`}>
          <ArrowLeftIcon />
        </button>
        <button onClick={nextSlide} className={`${arrowStyle}`}>
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  );
};

export default BlogFirst;
