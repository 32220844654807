import React from "react";
import Helmet from "react-helmet";
import Navbar from "../../components/Navbar";
import blog1image from "../../img/blog_image.jpg";
import blog2image from "../../img/erpImage.webp";
import blog3image from "../../img/erp_image.png";
import { Link } from "react-router-dom";

const BlogPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Kreatech is the Top 5 ERP Software Marketing Agency in Bangladesh
        </title>
        <meta
          name="description"
          content="Kreatech is a top software company specializing in automotive web development, full-stack web development, Advanced ERP software, and digital marketing."
        />
        <meta
          name="google-site-verification"
          content="fbyyYF-IP2NekJWzL5UxXvA0cwKzdHjxDUQBM-2EO_E"
        />
      </Helmet>
      <div>
        <Navbar />

        <div className="mt-20 mb-20 w-11/12 mx-auto flex flex-row justify-start items-start">
          <div className="w-[70%] flex flex-col gap-5">
            <div className="flex flex-col justify-center items-center">
              <img src={blog1image} className="w-4/5" />
              <div className="bg-white p-10 text-left w-3/4 mt-[-100px] shadow-lg">
                <p className="text-3xl pb-5">
                  5 tips for optimizing your website
                </p>
                <p className="text-xl">
                  Website optimization uses controlled experimentation to
                  improve a website's ability to drive business goals. To
                  enhance the performance of their website, website owners
                  implement...
                  <Link to="/5-tips-for-optimizing-website" target="_blank">
                    <span className="text-blue-500">Read More</span>
                  </Link>
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center">
              <img src={blog2image} className="w-4/5" />
              <div className="bg-white p-10 text-left w-3/4 mt-[-100px] shadow-lg">
                <p className="text-3xl pb-5">
                  Revolutionizing Business Efficiency: Unveiling the Wonders of
                  ERP Software with Kreatech
                </p>
                <p className="text-xl">
                  In the ever-evolving landscape of business operations, staying
                  ahead of the competition requires a strategic approach that
                  integrates technology seamlessly. Enterprise Resource...
                  <Link to="/erp-software-with-kreatech" target="_blank">
                    <span className="text-blue-500">Read More</span>
                  </Link>
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center">
              <img src={blog2image} className="w-4/5" />
              <div className="bg-white p-10 text-left w-3/4 mt-[-100px] shadow-lg">
                <p className="text-3xl pb-5">
                  Unlocking Growth: Why Kreatech ERP Software is the Perfect Fit
                  for Your Business
                </p>
                <p className="text-xl">
                  An ERP (Enterprise Resource Planning) Manager plays a pivotal
                  role in overseeing the implementation, maintenance, and
                  optimization of an organization's ERP...
                  <Link to="/kreatech-erp-for-business" target="_blank">
                    <span className="text-blue-500">Read More</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="w-[30%] flex flex-col justify-start items-start gap-10 mt-20">
            <label className="input input-bordered flex items-center gap-2">
              <input type="text" className="grow" placeholder="Search" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="w-4 h-4 opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <div className="text-left border-l-2 pl-5 border-slate-200">
              <p className="text-2xl mb-5 font-semibold">Catagories</p>
              <p className="font-normal hover:text-blue-500 cursor-pointer text-lg">
                ERP
              </p>
              <p className="font-normal hover:text-blue-500 cursor-pointer text-lg">
                SEO
              </p>
              <p className="font-normal hover:text-blue-500 cursor-pointer text-lg">
                Optimization
              </p>
              <p className="hover:text-blue-500 font-normal cursor-pointer text-lg">
                Others
              </p>
            </div>
            <div className="flex flex-col gap-5">
              <p className="text-2xl font-semibold mb-5 text-left border-b-2 border-blue-300">
                Recent Posts
              </p>
              <div className="flex flex-row justify-start items-start gap-5">
                <div className="flex-1">
                  <img src={blog1image} className="w-13 h-13" />
                </div>

                <div className="text-left flex-1">
                  <p>FEBRUARY 28, 2024</p>
                  <Link to="/5-tips-for-optimizing-website" target="_blank">
                    <p className="font-semibold text-lg hover:text-blue-400">
                      5 tips for optimizing your website
                    </p>
                  </Link>
                </div>
              </div>
              <div className="flex flex-row justify-start items-start gap-5">
                <div className="flex-1">
                  <img src={blog2image} className="w-13 h-13" />
                </div>

                <div className="text-left flex-1">
                  <p>FEBRUARY 03, 2024</p>
                  <Link to="/erp-software-with-kreatech" target="_blank">
                    <p className="font-semibold text-lg hover:text-blue-400">
                      Unveiling the Wonders of ERP Software with Kreatech
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogPage;
