import React from "react";
import ServiceFirst from "./sections/ServiceFirst";

const Services = () => {
  return (
    <div
      id="service"
      className="-mt-16 min-h-screen min-w-full flex flex-col gap-y-4 md:gap-y-8"
    >
      {/* Services we offer */}
      <ServiceFirst />
    </div>
  );
};

export default Services;
