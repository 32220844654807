import React from "react";
import Helmet from "react-helmet";
import Navbar from "../../components/Navbar";
import CareerFirst from "../sections/CareerFirst";

const CareerPage = () => {
  return (
    <>
    <Helmet>
    <meta name="google-site-verification" content="fbyyYF-IP2NekJWzL5UxXvA0cwKzdHjxDUQBM-2EO_E" />
    </Helmet>
    <div className="my-20">
      <Navbar />
      <CareerFirst />
    </div>
    </>
  );
};

export default CareerPage;
