import React from "react";
import BASISCertificate from "../../components/BASISCertificate";
import CaseForth from "./CaseForth";
import { useState } from "react";
const CaseThird = () => {
  const [showCertificate, setShowCertificate] = useState(false);
  const handleOnclose = () => setShowCertificate(false);
  return (
    <div className="bg-[#e3eef8] rounded-xl md:w-4/5 flex flex-col md:flex-row justify-between m-auto p-5 md:p-10 text-left">
      <div className="flex flex-col">
      <p className="font-bold text-xl md:text-3xl my-auto md:w-96">
        We're a proud member of BASIS.
      </p>
      
    <button className="flex flex-start text-blue-600" onClick={() => setShowCertificate(true)}>
      View Certificate
    </button>
    </div>

      <CaseForth onClose={handleOnclose} visible={showCertificate} />

      <BASISCertificate />
    </div>
  );
};

export default CaseThird;
