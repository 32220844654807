import React from "react";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const ReadMore = ({ routeTo, text }) => {
  return (
    <div
      className={`flex text-transparent bg-clip-text bg-gradient-to-tr from-black from-10% to-[#3581F0] to-90% font-semibold`}
    >
      <Link className="mt-[0.5px] ml-auto" to={`${routeTo}`}>
        {text}
      </Link>
      <p className="text-blue-500">
        <ChevronRightIcon />
      </p>
    </div>
  );
};

export default ReadMore;
