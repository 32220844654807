import React from "react";
import CareerFirst from "./sections/CareerFirst";
const Career = () => {
  return (
    <div
      id="career"
      className="pt-14 min-w-full flex flex-col gap-y-4 md:gap-y-8 bg-[#e3eef8]"
    >
      <CareerFirst />
    </div>
  );
};

export default Career;
