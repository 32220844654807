import React from "react";
import Header from "../../components/Header";
import { caseStudiesSecondContents } from "../../components/ThingsStandardize";
import { gradTextBlue } from "../../components/StyleStandardize";
import smallYellowBall from "../../img/smallYellowBall.png";
import HalfCircle from "../../components/HalfCircle";

const CaseSecond = () => {
  return (
    <div className="md:w-4/5 px-2 m-auto flex flex-col gap-10">
      <Header text="Way of building" textB="Great Software" blue borderMid />
      <div className="flex flex-col gap-8 text-left">
        {/* Decoration */}
        <img src={smallYellowBall} alt="Circle Arrow" className="w-5 h-5" />
        {/* Decoration */}
        {caseStudiesSecondContents.map((el, i) => {
          return (
            <>
              <div
                key={i}
                className={`flex ${
                  i % 2 === 0 ? "lg:flex-row-reverse" : "lg:flex-row"
                } flex-col items-center md:justify-around gap-8`}
              >
                <div className={`flex ${i === 2 ? "flex-col" : "flex-row"}`}>
                  {/* Decoration */}
                  {i === 0 && (
                    <div className="md:block hidden mt-10">
                      <HalfCircle
                        left
                        width="md:w-16"
                        widthSmall="w-10"
                        height="md:h-16"
                        heightSmall="h-10"
                      />
                    </div>
                  )}
                  {/* Decoration */}

                  <img
                    src={el.src}
                    alt={el.alt}
                    className="w-96 shadow-lg rounded-2xl"
                  />
                  {/* Decoration */}
                  {i !== 0 && (
                    <>
                      {i === 1 ? (
                        <div className="mt-auto md:block hidden mb-3">
                          <HalfCircle
                            width="md:w-16"
                            widthSmall="w-6"
                            height="md:h-16"
                            heightSmall="h-6"
                          />
                        </div>
                      ) : (
                        <div className="ml-10">
                          <HalfCircle
                            down
                            width="md:w-16"
                            widthSmall="w-6"
                            height="md:h-16"
                            heightSmall="h-6"
                          />
                        </div>
                      )}
                      {/* Decoration */}
                    </>
                  )}
                </div>
                <div className="flex flex-col gap-3 w-5/6 lg:w-1/2 text-lg">
                  <p className="font-bold text-2xl">{el.title}</p>
                  {el.text1}
                  <p>
                    {el.text21}{" "}
                    <span className={`${gradTextBlue} font-semibold`}>
                      {el.text2color}
                    </span>{" "}
                    {el.text22}
                  </p>
                  <div
                    className={`flex gap-2 ${gradTextBlue} font-semibold italic`}
                  >
                    <div
                      className={`from-[#2777E8] to-[#6AD7E5] bg-gradient-to-r ${
                        i === 2 ? "h-44 md:h-36" : "h-24 md:h-20"
                      } w-[2px]`}
                    />
                    <p className="my-auto w-3/4">"{el.qoute}"</p>
                  </div>
                  <div className="flex gap-3 items-center">
                    <img
                      src={el.author}
                      alt={el.authorAlt}
                      className="w-10 h-10 border rounded-full shadow-md"
                    />
                    <div className="text-base">
                      <p>{el.authorName}</p>
                      {el.authorTilte}
                    </div>
                  </div>
                </div>
              </div>
              {/* Decoration */}
              {i === 1 && (
                <img
                  src={smallYellowBall}
                  alt="Circle Arrow"
                  className="hidden lg:block lg:w-5 lg:h-5 ml-auto"
                />
              )}
              {/* Decoration */}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default CaseSecond;
