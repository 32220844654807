import React from "react";
import Helmet from "react-helmet";
import Navbar from "../../components/Navbar";
import HalfCircle from "../../components/HalfCircle";
import Header from "../../components/Header";
import { Link } from "react-scroll";
import { teamMembers } from "../../components/ThingsStandardize";
import "./AboutUsPage.css";
import basis_certificate from "../../img/basis.png";

const AboutUsPage = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kreatech | Total IT Solution provider in Bangladesh</title>
        <meta
          name="description"
          content="Kreatech provides client-focused IT services.top ranking SEO service| web design and development | graphic design | advanced erp development | digital marketing"
        />
        <meta
          name="google-site-verification"
          content="fbyyYF-IP2NekJWzL5UxXvA0cwKzdHjxDUQBM-2EO_E"
        />
        
      </Helmet>
      <div className="mt-[0px] min-h-[30vh] bg-white">
        <Navbar />
        <div className="">
          {/* <div className="ml-[10%]">
          <HalfCircle up />
        </div> */}
          <div className="bg-[#e3eef8] pt-[120px] pb-[80px] flex flex-col gap-5">
            <p className="text-2xl md:text-4xl font-bold">About</p>
            <p>
              <Link
                to="#about-1"
                onClick={() => scrollToSection("about-1")}
                style={{ cursor: "pointer" }}
              >
                Company Profile
              </Link>{" "}
              /{" "}
              <Link
                to="#about-2"
                onClick={() => scrollToSection("about-2")}
                style={{ cursor: "pointer" }}
              >
                Membership and Certification
              </Link>{" "}
              /{" "}
              <Link
                to="#members"
                onClick={() => scrollToSection("members")}
                style={{ cursor: "pointer" }}
              >
                People
              </Link>
            </p>
          </div>
          <div className="ml-[80%]">
            <HalfCircle down />
          </div>
        </div>

        <div
          id="about-1"
          className="about-1 justify-items-center mx-auto w-4/5 gap-5"
        >
          <div className="flex flex-col gap-5">
            <Header
              // text="Who We Are"
              blue
            />
            <h1 className="text-start text-[35px] font-bold leading-[30px] text-[#1A202C]">
              Who We Are
            </h1>
            <p className="text-justify md:text-lg p-2 hover:text-black hover:bg-gray-100 hover:shadow hover:rounded">
              At Kreatech, we're not your typical tech company. We're driven by
              a passionate team of young, dynamic professionals who are
              committed to transforming your tech dreams into vibrant,
              real-world solutions. What sets us apart? It's our fresh
              perspective and unwavering dedication to innovation. Since its
              inception in 2022 as KREATECH Canada, our journey has been driven
              by a commitment to excellence in the IT sector. In 2023, we
              kickstarted our branch in Bangladesh as Kreatech, an emerging
              software firm based in Bangladesh, dedicated to delivering
              cutting-edge software solutions on a global scale.
            </p>
          </div>
        </div>

        <div className="justify-items-center mx-auto w-4/5 gap-5 mt-[50px]">
          <div className="flex flex-col gap-5">
            <Header blue />
            <h1 className="text-start text-[35px] font-bold leading-[30px] text-[#1A202C]">
              Why Choose Us
            </h1>
            <div className="text-start">
              <h1 className="text-[#6AD7E5]">Fresh Perspectives</h1>
              <p>
                We bring a fresh perspective to the tech industry. We are not
                afraid to challenge the status quo and approach problems in new
                and innovative ways.
              </p>
            </div>
            <div className="mt-[30px] text-start">
              <h1 className="text-[#6AD7E5]">Customised Solutions</h1>
              <p>
                We understand that every business is unique, and we offer
                customized tech solutions tailored to each client's specific
                needs.
              </p>
            </div>
            <div className="mt-[30px] text-start">
              <h1 className="text-[#6AD7E5]">Cost-Effective</h1>
              <p>
                We believe that tech should be accessible to everyone,
                regardless of budget constraints.
              </p>
            </div>
            <div className="mt-[30px] text-start">
              <h1 className="text-[#6AD7E5]">Accessible Tech Solutions</h1>
              <p>
                Our products and services are designed with inclusivity in mind,
                ensuring that all users can enjoy the benefits of modern tech.
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <div className="ml-[10%]">
            <HalfCircle up />
          </div>
          <div className="bg-[#e3eef8] pt-[120px] pb-[80px] flex flex-col gap-5">
            <p className="text-2xl md:text-4xl font-bold">About</p>
            <p>
              <Link
                to="#about-1"
                onClick={() => scrollToSection("about-1")}
                style={{ cursor: "pointer" }}
              >
                Company Profile
              </Link>{" "}
              /{" "}
              <Link
                to="#about-2"
                onClick={() => scrollToSection("about-2")}
                style={{ cursor: "pointer" }}
              >
                Membership and Certification
              </Link>{" "}
              /{" "}
              <Link
                to="#members"
                onClick={() => scrollToSection("members")}
                style={{ cursor: "pointer" }}
              >
                People
              </Link>
            </p>
          </div>
          <div className="ml-[80%]">
            <HalfCircle down />
          </div>
        </div>

        <div
          id="about-2"
          className="about-2 justify-items-center mx-auto w-4/5 gap-5 pt-[30px] pb-[200px]"
        >
          <div className="flex flex-col gap-5">
            <Header
              // text="Who We Are"
              blue
            />
            <h1 className="text-start text-[35px] font-bold leading-[30px] text-[#1A202C]">
              Proud Member of BASIS
            </h1>
            <p className="text-justify md:text-lg p-2 hover:text-black hover:bg-gray-100 hover:shadow hover:rounded">
              Bangladesh Association of Software and Information Services
              (BASIS) is the national trade body for Software & IT Enabled
              Service industry of Bangladesh. Established in 1998, the
              association has been working with a vision of developing vibrant
              software & IT service industry in the country. Kreatech is proud
              to be a member of BASIS.
            </p>
            <div className="flex items-center justify-center">
              <img
                src={basis_certificate}
                alt="basis_certificate"
                className="h-1/4 w-1/2"
              />
            </div>
          </div>
        </div>

        <div className="">
          <div className="ml-[10%]">
            <HalfCircle up />
          </div>
          <div className="bg-[#e3eef8] pt-[120px] pb-[80px] flex flex-col gap-5">
            <p className="text-2xl md:text-4xl font-bold">About</p>
            <p>
              <Link
                to="#about-1"
                onClick={() => scrollToSection("about-1")}
                style={{ cursor: "pointer" }}
              >
                Company Profile
              </Link>{" "}
              /{" "}
              <Link
                to="#about-2"
                onClick={() => scrollToSection("about-2")}
                style={{ cursor: "pointer" }}
              >
                Membership and Certification
              </Link>{" "}
              /{" "}
              <Link
                to="#members"
                onClick={() => scrollToSection("members")}
                style={{ cursor: "pointer" }}
              >
                People
              </Link>
            </p>
          </div>
          {/* <div className="ml-[80%]">
          <HalfCircle down />
        </div> */}
        </div>

        {/* People */}
        <div class="one mt-3">
          <h1>Meet the Team</h1>
        </div>
        <div
          id="#members"
          className="members flex flex-row flex-wrap gap-8 p-6 justify-center"
        >
          {teamMembers.map((item, i) => {
            return (
              <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                <div class="h-80 w-72">
                  <img
                    class="h-full w-full object-cover transition-transform duration-500 group-hover:scale-125"
                    src={item.src}
                    alt={item.alt}
                  />
                </div>
                <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                <div class="absolute inset-0 flex translate-y-[40%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                  <h1 class="font-dmserif text-2xl font-bold text-white">
                    {item.memberName}
                  </h1>
                  <p class="mb-3 text-lg text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                    {item.memberPosition}
                  </p>
                </div>
              </div>
            );
          })}
          <p className="border-l-4 pl-3 border-blue-800 text-xl text-left mx-20">
            <span className="text-blue-500">Muqtadir Rashid Shaan</span> is a
            dedicated team member known for his adept handling of ad hoc tasks,
            ensuring smooth operations even in dynamic work environments. His
            proactive approach and invaluable contributions were pivotal in
            setting up Kreatech during its nascent stages, laying the foundation
            for its success.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
