import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { servings_industries } from "../../components/ThingsStandardize";
import ReadMore from "../../components/ReadMore";
const IndustryFirst = () => {
  return (
    <div className="bg-[#e3eef8] flex flex-col gap-10 py-10">
      <Header
        text="Industries and Sectors"
        textB="we are serving"
        blue
        borderMid
      />
      <div className="md:w-4/5 m-auto flex flex-col gap-10 px-2">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          {servings_industries.map((el, i) => {
            return (
              <div
                key={i}
                className="card bg-white px-4 py-8 flex flex-col md:flex-row gap-3 shadow-md"
              >
                <img
                  src={el.src}
                  alt={el.alt}
                  className={`card p-1 w-12 h-12 ${el.bg}`}
                />
                <div className="text-left flex flex-col gap-2">
                  <p className="font-bold text-lg">{el.title}</p>
                  <div>
                    {el.text}
                  </div>
                  <Link to="/Industry"><button className="ml-auto text-[#2777E8]">Know More</button></Link>
                </div>
              </div>
            );
          })}
        </div>
        <ReadMore routeTo="/Industry" text="Read more on the industries" />
      </div>
    </div>
  );
};

export default IndustryFirst;
