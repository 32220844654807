import basis_certificate from "../../img/basis.png";


const  CaseForth = ({visible, onClose}) => {
    if (!visible) return null;
    return ( 
        <div className="fixed inset-0 mt-10 bg-opacity-40 backdrop-blur-sm flex items-center justify-center">
            <div className="flex flex-col justify-center items-center">
                <button className="" onClick={onClose}>X</button>
                <img src={basis_certificate} className="w-[60%]" alt="basisCertificate" />
            </div>
        </div>
     );
}
 
export default CaseForth;