import React from "react";
import { careerContents } from "../../components/ThingsStandardize";
import ReadMore from "../../components/ReadMore";
const CareerFirst = () => {
  return (
    <div className="md:w-4/5 m-auto flex flex-col gap-10 py-10 px-5">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-10">
        {/* left column */}
        <div className="flex flex-col gap-6 text-left w-5/6">
          <p className="font-semibold text-lg">JOIN US</p>
          <p className="font-bold text-4xl">
            Why you Should Join Our Awesome Team
          </p>
          <p>
            We want to feel like home when you are working at Kreatech & for
            that we have curated a great set of benefits for you.It all starts
            with the free snacks!
          </p>
        </div>

        {/* right column */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {careerContents.map((el, i) => {
            return (
              <div
                key={i}
                className="flex flex-col gap-3 items-start text-left"
              >
                <img
                  src={el.src}
                  alt={el.alt}
                  className={`p-2 w-12 h-12 shadow-md ${el.bg}`}
                />
                <p className="font-bold text-lg">{el.title}</p>
                <p>{el.text}</p>
              </div>
            );
          })}
        </div>
      </div>
      <ReadMore routeTo="/Career" text="See all Open Positions" />
    </div>
  );
};

export default CareerFirst;
