import React, { useState } from "react";
import HamburgerButton from "./Hamburger";
import NavbarLogo from "./NavbarLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import "./Navbar.css";
import { gradBGBlue } from "./StyleStandardize";
import { useLocation, Link } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  const navbar = [
    {
      name: "Services",
      path: "/Services",
    },
    {
      name: "About us",
      path: "/AboutUs",
    },
    {
      name: "Case Studies",
      path: "/CaseStudies",
    },
    {
      name: "Industry",
      path: "/Industry",
    },
    {
      name: "Blog",
      path: "/Blog",
    },
    {
      name: "Career",
      path: "/Career",
    },
  ];

  const handleClick = () => {
    setActive(false);
  };

  return (
    <nav className="top-0" role="navigation" aria-label="main navigation">
      <div className="container mx-auto">
        <div
          className={`z-40 fixed transition-colors top-0 left-0 right-0 w-full
        lg:w-max bg-opacity-50 backdrop-blur-lg lg:backdrop-blur-0 h-14
        flex lg:flex-row justify-between px-2 lg:px-8 lg:max-w-7xl items-center shadow-md lg:shadow-none`}
        >
          <div onClick={handleClick}>
            <NavbarLogo link />
          </div>

          <div className="flex justify-center items-center lg:hidden p-1 rounded-md">
            <HamburgerButton
              active={active}
              onClick={() => setActive(!active)}
            />
          </div>
        </div>

        <ul
          className={`z-30 fixed top-0 lg:right-0 w-full lg:bg-opacity-50 backdrop-blur ${
            active ? "translate-x-0 text-black text-2xl" : "-translate-x-full"
          } lg:translate-x-0 h-[calc(100vh_-_3rem)] lg:h-14 mt-12 transition-transform flex flex-col justify-center lg:justify-end lg:flex-row gap-7 text-2xl lg:text-lg text-center items-center lg:mt-0 lg:px-8 font-medium shadow-md`}
        >
          {navbar.map((el) => (
            <li
              key={el.path}
              className={
                (location.pathname === el.path
                  ? "text-[#3F9AE8] border-b-2 border-[#3F9AE8] font-semibold"
                  : "text-black") + " hover:text-[#388FE8] cursor-pointer"
              }
            >
              <Link to={el.path} onClick={handleClick}>
                <p className="px-2">{el.name}</p>
              </Link>
            </li>
          ))}
          <div className="flex flex-row gap-5 lg:pl-10">
            <Link
              to="https://www.instagram.com/kreatech_it?igshid=YzAwZjE1ZTI0Zg"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="hover:text-[#E1306C] cursor-pointer"
              />
            </Link>

            <Link
              to="https://www.facebook.com/kreatech.ca?mibextid=ZbWKwL"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                className="hover:text-[#4267B2] cursor-pointer"
              />
            </Link>

            <Link
              to="https://www.linkedin.com/company/kreatech-ca/"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faLinkedinIn}
                className="hover:text-[#006AFF] cursor-pointer"
              />
            </Link>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
