import React from "react";
import basisCertificate from "../img/basisCertificate.png";
import topShine from "../img/topShine.png";

const BASISCertificate = () => {
  return (
    <div className="flex flex-col gap-2 items-center">
      <img src={topShine} alt="Decoration" />
      <img
        src={basisCertificate}
        alt="Certificate from BASIS"
        className="w-40 z-10 shadow-lg"
      />
      <img src={topShine} alt="Decoration" className="rotate-180" />
    </div>
  );
};

export default BASISCertificate;
