import React from "react";
import CaseFirst from "./sections/CaseFirst";
import CaseSecond from "./sections/CaseSecond";
import CaseThird from "./sections/CaseThird";

const CaseStudies = () => {
  return (
    <div
      id="caseStudies"
      className="pt-16 min-w-full flex flex-col gap-y-4 md:gap-y-8"
    >
      {/* Our Recent Case Studies */}
      <CaseFirst />
      {/* Way of building Great Software */}
      <CaseSecond />
      {/* BASIS certificate */}
      <CaseThird />
    </div>
  );
};

export default CaseStudies;
