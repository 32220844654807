import React, { useEffect, useState } from "react";
import HalfCircle from "../../components/HalfCircle";
import ArrowLeftIcon from "@mui/icons-material/ArrowBack";
import ArrowRightIcon from "@mui/icons-material/ArrowForward";
import { arrowStyle, gradTextBlue } from "../../components/StyleStandardize";
import { services } from "../../components/ThingsStandardize";
import ReadMore from "../../components/ReadMore";

const ServiceFirst = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalServices = services.length;
  const slidesToShowLarge = 3;
  const slidesToShowMedium = 1;

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + totalServices) % totalServices
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalServices);
  };

  const getVisibleCardsLarge = () => {
    const visibleCards = [];
    for (let i = 0; i < slidesToShowLarge; i++) {
      let opacity = 1;
      if (i === 0 || i === slidesToShowLarge - 1) {
        opacity = 0.5;
      } else if (i === Math.floor(slidesToShowLarge / 2)) {
        opacity = 1;
      }

      visibleCards.push({
        ...services[(currentIndex + i) % totalServices],
        opacity: opacity,
      });
    }
    return visibleCards;
  };

  const getVisibleCardsMdSm = () => {
    const visibleCards = [];
    for (let i = 0; i < slidesToShowMedium; i++) {
      visibleCards.push(services[(currentIndex + i) % totalServices]);
    }
    return visibleCards;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, 7000);
    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex]);

  return (
    <div className="">
      <div className="ml-[10%]">
        <HalfCircle up />
      </div>
      <div className="bg-[#e3eef8] py-10 flex flex-col gap-5">
        <p className="text-2xl md:text-4xl font-bold">Services we offer</p>

        <div className="flex flex-col gap-5 md:w-4/5 m-auto">
          {/* For large size screens */}
          <div className="hidden lg:flex flex-row flex-wrap gap-3 justify-center overflow-hidden">
            {getVisibleCardsLarge().map((el, i) => {
              return (
                <div
                  key={i}
                  className={`card card-compact w-[250px] bg-base-100 text-left shadow-md`}
                  style={{ opacity: el.opacity }}
                >
                  <div className="card-body h-80">
                    <div className="card-title flex flex-col items-start">
                      <img
                        src={el.icono}
                        alt={el.alt}
                        className="border border-blue-300 rounded-full w-8 h-8"
                      />
                      <p
                        className={`font-semibold  ${
                          el.opacity === 1 ? gradTextBlue : "text-black"
                        }`}
                      >
                        {el.title}
                      </p>
                    </div>
                    <p className="text-black">{el.text}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {/* For small and medium size screens */}
          <div className="block m-auto lg:hidden">
            {getVisibleCardsMdSm().map((el, i) => {
              return (
                <div
                  key={i}
                  className={`card w-[300px] bg-base-100 text-left`}
                  style={{ opacity: el.opacity }}
                >
                  <div className="card-body h-80">
                    <div className="card-title flex flex-col items-start">
                      <img
                        src={el.icono}
                        alt={el.alt}
                        className="border border-blue-300 rounded-full w-8 h-8"
                      />
                      <p
                        className={`font-semibold  ${
                          el.opacity === 1 ? gradTextBlue : "text-black"
                        }`}
                      >
                        {el.title}
                      </p>
                    </div>
                    <p className="text-black">{el.text}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Left and Right arrow */}
          <div className="m-auto flex gap-6">
            <button onClick={prevSlide} className={`${arrowStyle}`}>
              <ArrowLeftIcon />
            </button>
            <button onClick={nextSlide} className={`${arrowStyle}`}>
              <ArrowRightIcon />
            </button>
          </div>

          <ReadMore routeTo="/Services" text="Read more on the services" />
        </div>
      </div>

      <div className="ml-[80%]">
        <HalfCircle down />
      </div>
    </div>
  );
};

export default ServiceFirst;
