import serviceBrandStrategy from "../img/serviceBrandStrategy.png";
import serviceAppDev from "../img/serviceAppDev.png";
import serviceERPService from "../img/serviceERPService.png";
import serviceUXResearch from "../img/serviceUXResearch.png";
import serviceSEO from "../img/serviceSEO.png";
import serviceAppMaintenance from "../img/serviceAppMaintenance.png";
import serviceAppTesting from "../img/serviceAppTesting.png";

import rating5 from "../img/rating5.png";

import aboutCommentSumon from "../img/aboutCommentSumon.jpg";
import aboutCommentSCS from "../img/aboutCommentSCS.jpg";

import profileImage from "../img/profileImage.webp";

import industryConstruction from "../img/industryConstruction.png";
import industryCourier from "../img/industryCourier.png";
import industryFood from "../img/industryFood.png";
import industryGarments from "../img/industryGarments.png";
import industryRealEstate from "../img/industryRealEstate.png";
import industrySMEs from "../img/industrySMEs.png";

import careerTeamWork from "../img/careerTeamWork.png";
import careerSecuredFututre from "../img/careerSecuredFututre.png";
import careerLearningOpportunity from "../img/careerLearningOpportunity.png";
import careerUpgradeSkills from "../img/careerUpgradeSkills.png";

import blogERPTrends from "../img/blogERPTrends.png";
import blogOptimisingWeb from "../img/blogOptimisingWeb.png";
import blogResponsiveWeb from "../img/blogResponsiveWeb.png";
import blogEvolutionERP from "../img/blogEvolutionERP.png";
import blogAIImpact from "../img/blogAIImpact.png";

import backendNodeJS from "../img/backendNodeJS.png";
import backendPHP from "../img/backendPHP.png";
import backendJava from "../img/backendJava.png";
import backendNETCore from "../img/backendNETCore.png";
import backendPython from "../img/backendPython.png";
import backendRubyOnRails from "../img/backendRubyOnRails.png";
import backendGo from "../img/backendGo.png";

import databaseMongoDB from "../img/databaseMongoDB.png";
import databaseMySQL from "../img/databaseMySQL.png";
import databaseMicrosoftSQLServer from "../img/databaseMicrosoftSQLServer.png";
import databaseOracle from "../img/databaseOracle.png";
import databasePostgreSQL from "../img/databasePostgreSQL.png";
import databaseSQLite from "../img/databaseSQLite.png";

import frontendAngular from "../img/PHP.png";
import frontendFlutter from "../img/frontendFlutter.png";
import frontendVue from "../img/frontendVue.png";
import frontendJavaScript from "../img/frontendJavaScript.png";
import frontendNativeScript from "../img/frontendNativeScript.png";
import frontendReactJS from "../img/frontendReactJS.png";
import frontendTypeScript from "../img/frontendTypeScript.png";

import cmsWordPress from "../img/cmsWordPress.png";
import cmsStrapi from "../img/cmsStrapi.png";
import cmsMagento from "../img/cmsMagento.png";
import cmsJoomla from "../img/cmsJoomla.png";
import cmsGhost from "../img/cmsGhost.png";
import cmsDrupal from "../img/cmsDrupal.png";
import cmsContentful from "../img/cmsContentful.png";

import caseStudiesArian from "../img/caseStudiesArian.png";
import caseStudiesENS from "../img/caseStudiesENS.png";
import caseStudiesSCS from "../img/caseStudiesSCS.png";


import devopsGit from "../img/devopsGit.png";
import docker from "../img/docker.png";
import devopsKubernetes from "../img/devopsKubernetes.png";
import devopsPuppet from "../img/devopsPuppet.png";
import devopsAnsible from '../img/devopsAnsible.png';
import devopsCircleci from "../img/devopsCircleci.png";

import caseSecondDecisionMaking from "../img/caseSecondDecisionMaking.png";
import caseSecondIterativeTesting from "../img/caseSecondIterativeTesting.png";
import caseSecondDivideAndConquer from "../img/caseSecondDivideAndConquer.png";
import gradyBooch from "../img/gradyBooch.png";
import billGates from "../img/billGates.png";
import linusTorvalds from "../img/linusTorvalds.png";

import rose from "../img/rose.jpg";
import lamia from "../img/lamia.jpg";
import jamil from "../img/jamil.jpg";
import shafin from "../img/shafin.jpg";
import olee from "../img/olee.jpg";
import humayra from "../img/humayra.jpg"

import optimize from "../img/optimize.jpeg";

export const comments = [
  {
    comment:
      "I recommend Kreatech as they have really helped us optimise our website. They kept us aligned in all of the steps of creating our website. Truly one of the best IT firms in Dhaka in today’s times. All of their staffs are extremely helpful too.",
  },
  {
    comment:
      "I recommend Kreatech as they have really helped us optimise our website. They kept us aligned in all of the steps of creating our website. Truly one of the best IT firms in Dhaka in today’s times. All of their staffs are extremely helpful too.",
  },
  {
    comment:
      "I recommend Kreatech as they have really helped us optimise our website. They kept us aligned in all of the steps of creating our website. Truly one of the best IT firms in Dhaka in today’s times. All of their staffs are extremely helpful too.",
  },
  {
    comment:
      "I recommend Kreatech as they have really helped us optimise our website. They kept us aligned in all of the steps of creating our website. Truly one of the best IT firms in Dhaka in today’s times. All of their staffs are extremely helpful too.",
  },
];

export const commentsOwner = [
  {
    src: aboutCommentSCS,
    alt: "C1",
    name: "Asha Rahman",
    position: "Chief Accountant of SCS Express",
    rating: rating5,
    ratingAlt: "Rating 5",
  },
  {
    src: profileImage,
    alt: "C1",
    name: "Mahbubur Rahman",
    position: "CEO of Homes by Ens",
    rating: rating5,
    ratingAlt: "Rating 5",
  },
  {
    src: aboutCommentSumon,
    alt: "C1",
    name: "Imran",
    position: "Manager of M/S Sumon Enterprise",
    rating: rating5,
    ratingAlt: "Rating 5",
  },
  {
    src: profileImage,
    alt: "C1",
    name: "Md Hafijul Islam",
    position: "Arian Traders",
    rating: rating5,
    ratingAlt: "Rating 5",
  },
];

export const services = [
  {
    title: "SEO",
    text: "We specialise in search engine optimisation (SEO) to help our clients achieve higher search engine rankings and drive more traffic to their website. We have a team of SEO experts who are well-versed in the latest SEO techniques and strategies.",
    icono: serviceSEO,
    alt: "SEO",
  },
  {
    title: "Brand Strategy",
    text: "Together, our brand strategy and messaging services help businesses to create a consistent and powerful brand identity that connects with customers on an emotional level.",
    icono: serviceBrandStrategy,
    alt: "Strategy",
  },
  {
    title: "Mobile App Development",
    text: "We specialise in developing custom mobile apps for both iOS and Android platforms, using the latest technologies and programming languages (Flutter, React Native).",
    icono: serviceAppDev,
    alt: "App develop",
  },
  {
    title: "ERP Development",
    text: "Enterprise resource planning (ERP) involves  managing day-to-day business activities such as accounting, procurement, project management, risk management and compliance, and supply chain operations.",
    icono: serviceERPService,
    alt: "ERP develop",
  },
  {
    title: "UX Research & Design",
    text: "UX researchers work to uncover insights that inform the design process, ensuring that the final product meets user needs and expectations. UX designers work closely with UX researchers to create wireframes, prototypes, and high-fidelity designs.",
    icono: serviceUXResearch,
    alt: "UX UI",
  },
  {
    title: "Mobile App Testing",
    text: "Mobile app testing process includes the following steps: Functional Testing, Compatibility Testing, Performance Testing, Usability Testing, Security Testing and Automation Testing.",
    icono: serviceAppTesting,
    alt: "App Testing",
  },
  {
    title: "App Maintenance",
    text: "Our app maintenance and support services include: Regular Updates and Maintenance, Security and Data Protection, User Support, Performance Monitoring, and App Store Updates.",
    icono: serviceAppMaintenance,
    alt: "App maintenance",
  },
];

export const servings_industries = [
  {
    src: industryConstruction,
    alt: "Construction",
    title: "Construction",
    text: "Kreatech, a tech leader in construction, pioneers software solutions. From project management to BIM integration, their tools optimize workflows, enhance collaboration, and elevate data insights. Empowering builders with innovation for efficient, future-ready construction projects.",
    bg: "bg-gradient-to-tr from-[#27272E] from-10% to-[#29272E] to-90%",
    gradText:
      "text-transparent bg-clip-text bg-gradient-to-tr from-[#27272E] from-10% to-[#29272E] to-90%s",
  },
  {
    src: industryGarments,
    alt: "Garments",
    title: "Garments",
    text: "Kreatech provides a  visionary in garment software, revolutionizes the fashion industry. From design to distribution, their tailored solutions optimize production, streamline workflows, and elevate supply chain management. Redefining garment creation with innovation and efficiency.",
    bg: "bg-gradient-to-tr from-[#FFF5F4] from-10% to-[#FFB800] to-90%",
    gradText:
      "text-transparent bg-clip-text bg-gradient-to-tr from-[#FFF5F4] from-10% to-[#FFB800] to-90%",
  },
  {
    src: industryCourier,
    alt: "Courier Service",
    title: "Courier Service",
    text: "Kreatech excels in courier service ERP software, optimizing logistics. Their solutions enhance order processing, route optimization, and real-time tracking, ensuring streamlined operations. Revolutionizing courier services with efficiency and advanced technology.",
    bg: "bg-gradient-to-tr from-[#FF3D9A] from-10% to-[#FF92AE] to-90%",
    gradText:
      "text-transparent bg-clip-text bg-gradient-to-tr from-[#FF3D9A] from-10% to-[#FF92AE] to-90%",
  },
  {
    src: industryRealEstate,
    alt: "Real Estate",
    title: "Real Estate",
    text: "Kreatech pioneers modern web development for real estate, crafting responsive interfaces and robust functionalities. Their solutions integrate property listings, streamline user experiences, and enhance online presence. Elevating real estate companies with cutting-edge technology.",
    bg: "bg-gradient-to-tr from-[#24E795] from-10% to-[#E3EEF8] to-90%",
    gradText:
      "text-transparent bg-clip-text bg-gradient-to-tr from-[#24E795] from-10% to-[#E3EEF8] to-90%",
  },
  {
    src: industrySMEs,
    alt: "Small-to-Medium Enterprises (SMEs)",
    title: "Small-to-Medium Enterprises (SMEs)",
    text: "Kreatech specializes in empowering small to medium enterprises with comprehensive solutions. From ERP software for seamless operations to logo and graphic design, and web development, they elevate brand aesthetics and efficiency, fostering success in the digital realm.",
    bg: "bg-gradient-to-tr from-[#F7936F] from-10% to-[#FFEF5E] to-90%",
    gradText:
      "text-transparent bg-clip-text bg-gradient-to-tr from-[#F7936F] from-10% to-[#FFEF5E] to-90%",
  },
  {
    src: industryFood,
    alt: "Food and Drinks",
    title: "Food & Drinks",
    text: "Kreatech, a dynamic force in the food and drink industry, offers a full spectrum of services. From enticing logo and menu designs to strategic digital marketing and seamless web development, they craft a delectable online presence, enhancing brand identity and customer engagement.",
    bg: "bg-gradient-to-tr from-[#57007B] from-10% to-[#F76680] to-90%",
    gradText:
      "text-transparent bg-clip-text bg-gradient-to-tr from-[#57007B] from-10% to-[#F76680] to-90%",
  },
];

export const careerContents = [
  {
    src: careerTeamWork,
    alt: "Team",
    bg: "bg-[#E7F2FF]",
    title: "Team work",
    text: "We believe in collaboration and helping each other out, when it comes to collaborating with clients and among us.",
  },
  {
    src: careerSecuredFututre,
    alt: "Secured Future",
    bg: "bg-[#F1F7E8]",
    title: "Secured Future",
    text: "We are proud to have our tech prints globally. Once you are a part of us, Kreatech will be there with you, always.",
  },
  {
    src: careerLearningOpportunity,
    alt: "Learning Opportunity",
    bg: "bg-[#EFF2F5]",
    title: "Learning Opportunity",
    text: "We are all for learning and growing. We don’t want you to be limited and are constantly sharing resources on how you may stand out from the crowd.",
  },
  {
    src: careerUpgradeSkills,
    alt: "Upgrade Skills",
    bg: "bg-[#FFEEED]",
    title: "Upgrade Skills",
    text: "We work across all industries. We believe that by working with us you can upgrade your soft skills, expand your knowledge, while polishing your technical skills.",
  },
];

export const blogContents = [
  {
    id: 1,
    src: blogERPTrends,
    alt: "ERP Trends",
    text: "The Latest Trends in ERP Solutions for Garments",
    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 2,
    src: blogOptimisingWeb,
    src1: optimize,
    alt: "Web Optimization",
    text: "5 Tips for Optimising your Website",
    description: (
      <ul>
        <p>
          Website optimization uses controlled experimentation to improve a website's ability to drive business goals. To enhance the performance of their website, website owners implement A/B Testing to experiment with variations on pages of their website to determine which changes will ultimately result in more conversions (e.g. demo requests, increases in organic search results, more purchases, reduced customer service time, etc.).
        </p>
        <p>
          Website optimization follows the same principles used in conversion rate optimization and is based on the scientific method. The best five strategies for optimizing a website are:
          <ol>
            <li>Mobile Responsiveness: Ensure that your website is mobile-friendly and responsive. With increasing users accessing the internet on mobile devices, your site must look and function well on smartphones and tablets. Google also considers mobile-friendliness a ranking factor, so optimizing for mobile can improve your search engine rankings.</li>
            <li>Page Loading Speed: Users expect websites to load quickly, and search engines take page speed into account when ranking sites. Compress images, minify CSS and JavaScript files, and leverage browser caching to reduce load times. You can use tools like Google PageSpeed Insights or GTmetrix to analyze your site's speed and get recommendations for improvement.</li>
            <li>Optimize Content for SEO: Improve your website's visibility on search engines by optimizing content for relevant keywords. Conduct keyword research to identify terms your target audience will likely use, and incorporate them naturally into your titles, headings, and body content. Create high-quality, engaging content that provides value to your users. Use descriptive meta titles and meta descriptions for each page to improve click-through rates from search engine results.</li>
            <li>User-Friendly Navigation: Make sure your website is easy to navigate. A straightforward and intuitive navigation menu helps users quickly find the information they're looking for. Organize your content logically, and use descriptive labels for navigation links. Implement breadcrumb navigation to show users the path to their current location on your site. A user-friendly website improves the user experience and contributes to better search engine rankings.</li>
            <li>Secure Your Website with HTTPS: Security is a growing concern on the internet. Switching to HTTPS (SSL/TLS encryption) secures the data transmitted between your website and its users and contributes positively to search engine rankings. Google has indicated that HTTPS is a ranking factor, and browsers may display warnings for non-secure sites. Many web hosting providers offer free SSL certificates, making it easier than ever to secure your website.</li>
          </ol>
        </p>
        <p>
          Website optimization can also be connected to improving the speed and reliability of a website's performance. This is implicitly relevant to the goal of website optimization as completing a desired action on a website. Poor website performance, such as latency or errors, can prohibit visitors from taking action due to an inability to navigate the website.
        </p>
      
      </ul>
    ),
  },
  {
    id: 3,
    src: blogResponsiveWeb,
    alt: "Responsive Website",
    text: "The Importance of Responsive Web Design",
    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 4,
    src: blogEvolutionERP,
    alt: "ERP Evolution",
    text: "The Evolution of ERP Solutions since 2010",
    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 5,
    src: blogAIImpact,
    alt: "AI imapct",
    text: "The Impact of AI in Human Society",
    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
];

export const techCategories = [
  {
    title: "Backend",
    images: [
      backendNodeJS,
      backendPHP,
      backendJava,
      backendNETCore,
      backendPython,
      backendRubyOnRails,
      backendGo,
    ],
  },
  {
    title: "Frontend",
    images: [
      frontendReactJS,
      frontendAngular,
      frontendVue,
      frontendJavaScript,
      frontendTypeScript,
      frontendNativeScript,
      frontendFlutter,
    ],
  },
  {
    title: "Databases",
    images: [
      databaseMySQL,
      databaseMongoDB,
      databaseMicrosoftSQLServer,
      databaseOracle,
      databasePostgreSQL,
      databaseSQLite,
    ],
  },
  {
    title: "CMS",
    images: [
      cmsWordPress,
      cmsStrapi,
      cmsMagento,
      cmsJoomla,
      cmsGhost,
      cmsDrupal,
      cmsContentful,
    ],
  },

  { title: "DevOps", images: [devopsGit, docker, devopsKubernetes, devopsPuppet, devopsCircleci, devopsAnsible], },
];

export const caseStudiesContents = [
  {
    src: caseStudiesArian,
    alt: "Arian Traders",
    title: "Website Development and SEO Enhancement for Arian Traders",
    text: "Empower our online presence with Arian Traders! Our Website Development and SEO Enhancement offer a dynamic digital edge. Customized websites, intuitive navigation, and strategic SEO elevate visibility and success. Transform our brand's online impact with Arian Traders.",
    bg: "bg-[#FCF6F5]",
  },
  {
    src: caseStudiesENS,
    alt: "Homes by ENS",
    title: "Web Hosting & Scheduled Maintenance for Homes by Ens, Canada",
    text: "Born out of a vision, a single-minded objective that puts service before anything else, Swift Clearance and Forwarding Corp. surging forth to deliver the best services in the shipping and logistics scenario. Its meteoric rise stems out of a solid foundation. The management boasts of over 20 years of rich and varied experience in the shipping and freight forwarding industry.",
    bg: "bg-[#E7E8D1]",
  },
  {
    src: caseStudiesSCS,
    alt: "SCS Express",
    title: "Accounts ERP Development for SCS Express",
    text: "SCS Express excels in Accounts ERP Development, offering tailored solutions for seamless financial management. It epitomizes efficiency and precision with integrated modules, automation, and real-time insights. Security, scalability, and continuous improvement define their commitment.",
    bg: "bg-[#FFF4F4]",
  },
];

export const caseStudiesSecondContents = [
  {
    src: caseSecondDivideAndConquer,
    alt: "Divide and Conquer",
    title: "Divide and Conquer",
    text1:
      "Just like building with LEGO, we break down big projects into smaller, manageable parts. It’s like organising puzzle pieces – it's easier to handle and connect them to build something incredible!",
    text21: "Our",
    text2color: "delivery model",
    text22: "helps you cut costs and deliver within budget.",
    qoute:
      "The function of good software is to make the complex appear simple.",
    author: gradyBooch,
    authorAlt: "Grady Booch",
    authorName: "Grady Booch",
    authorTilte: "Renowned Software Architech",
  },

  {
    src: caseSecondIterativeTesting,
    alt: "Iterative Testing and Feedback",
    title: "Iterative Testing and Feedback",
    text1:
      "We implement continuous testing and user feedback loops to refine your software. Agile methodologies and constant user input lead to adaptive, user-centric software.",
    text21: "For software, we",
    text2color: "test and tweak",
    text22:
      ", using what we learn from unhappy customers to make things better.",
    qoute: "Your most unhappy customers are your greatest source of learning",
    author: billGates,
    authorAlt: "Bill Gates",
    authorName: "Bill Gates",
    authorTilte: "",
  },

  {
    src: caseSecondDecisionMaking,
    alt: "Data-Driven Decision Making",
    title: "Data-Driven Decision Making",
    text1:
      "Leverage data analytics to understand user behaviour and software performance. Numbers show you what people like or don’t.",
    text21: "We combine that with your ideas to steer your",
    text2color: "software toward success",
    text22: ".",
    qoute:
      "Most good programmers do programming not because they expect to get paid or get adulation by the public, but because it is fun to program.",
    author: linusTorvalds,
    authorAlt: "Linus Torvalds",
    authorName: "Linus Torvalds",
    authorTilte: "CEO",
  },
];

export const teamMembers = [
  {
    src: rose,
    memberName: "Meghla Mehnaz Rose",
    alt: "Meghla Mehnaz Rose",
    memberPosition: "Team Lead",

  },
  {
    src: jamil,
    memberName: "Jamil Reza",
    alt: "Jamil Reza",
    memberPosition: "Junior Web Developer",

  },

  {
    src: lamia,
    memberName: "Lamia Hossain",
    alt: "Lamia Hossain",
    memberPosition: "Junior Web Developer",

  },
  {
    src: shafin,
    memberName: "Shafin Mahmud Nahian",
    alt: "Shafin Mahmud Nahian",
    memberPosition: "Junior Backend Developer",

  },
  {
    src: olee,
    memberName: "Olee Ahmmed",
    alt: "Olee Ahmmed",
    memberPosition: "Junior Backend Developer",

  },
  {
    src: humayra,
    memberName: "Kazi Humaira Haque",
    alt: "Kazi Humaira Haque",
    memberPosition: "Digital Marketing Specialist",

  },

];

export const blogs = [
  {
    id: 1,
    authorName: "Humayra",
    description: "Website optimization uses controlled experimentation to improve a website's ability to drive business goals. To enhance the performance of their website, website owners implement A/B Testing to experiment with variations on pages of their website to determine which changes will ultimately result in more conversions (e.g. demo requests, increases in organic search results, more purchases, reduced customer service time, etc.).Website optimization follows the same principles used in conversion rate optimization and is based on the scientific method. The best five strategies for optimizing a website are"
    
  },

  {
    id: 2,
    authorName: "Humayra",
    description: "Website optimization uses controlled experimentation to improve a website's ability to drive business goals. To enhance the performance of their website, website owners implement A/B Testing to experiment with variations on pages of their website to determine which changes will ultimately result in more conversions (e.g. demo requests, increases in organic search results, more purchases, reduced customer service time, etc.).Website optimization follows the same principles used in conversion rate optimization and is based on the scientific method. The best five strategies for optimizing a website are"
  }
]
