import React from "react";
import Header from "../../components/Header";
import arrowLeft from "../../img/arrowLeft.png";
import arrowRight from "../../img/arrowRight.png";
import smallYellowBall from "../../img/smallYellowBall.png";
import { caseStudiesContents } from "../../components/ThingsStandardize";
import ReadMore from "../../components/ReadMore";

const CaseFirst = () => {
  return (
    <div className="bg-[#e3eef8] flex flex-col gap-10 py-10">
      {/* For Decoration */}
      <div className="hidden md:w-4/5 m-auto md:flex justify-between -mt-28">
        <div className="flex flex-row gap-2">
          <img src={arrowLeft} alt="Circle Arrow" className="w-40" />
          <img
            src={smallYellowBall}
            alt="Circle Arrow"
            className="mt-auto w-5 h-5"
          />
        </div>
        <img src={arrowRight} alt="Circle Arrow" className="w-28" />
      </div>

      <Header text="Our recent" textB="Case studies" blue borderMid />

      <div className="md:w-4/5 m-auto flex flex-col gap-10 px-5 text-left">
        {caseStudiesContents.map((el, i) => {
          return (
            <div
              key={i}
              className={`flex flex-col lg:flex-row justify-center items-center gap-5 ${
                i % 2 === 1 ? "lg:flex-row-reverse" : "" // Reverse order for odd rows on lg screens
              }`}
            >
              <img src={el.src} alt={el.alt} className="flex-1" />
              <div
                className={`flex-1 flex flex-col gap-5 p-7 ${
                  i % 2 === 1 ? "border-l-2 border-b-2 border-indigo-300" : "border-r-2 border-b-2 border-indigo-300"
                }`}
              >
                <p className="font-bold text-xl">{el.title}</p>
                <p className="text-lg">{el.text}</p>
                <ReadMore routeTo="/CaseStudies" text="Read more" />
              </div>
            </div>
          );
        })}
        <ReadMore routeTo="/CaseStudies" text="Read more case studies" />
      </div>
    </div>
  );
};

export default CaseFirst;
