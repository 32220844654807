import React from "react";
import AboutUs from "../About/AboutUs";
import Services from "../Services/Services";
import CaseStudies from "../CaseStudies/CaseStudies";
import Blog from "../Blog/Blog";
import Industry from "../Industry/Industry";
import NavbarMain from "../components/NavbarMain";
import Career from "../Career/Career";
import Home from "./Home";
import Contact from "./Contact";
import Navbar from "../components/Navbar";
const Main = () => {
  return (
    <div className="flex flex-col bg-white gap-y-7 text-black">
      <Navbar />
      <Home />
      <Services />
      <AboutUs />
      <CaseStudies />
      <Industry />
      <Blog />
      <Career />
    </div>
  );
};

export default Main;
