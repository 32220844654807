import React from "react";
import { Link } from "react-router-dom";
import home from "../videos/home1.mp4";
import hero from "../img/hero.png";
import './Home.scss';
const Home = () => {
  return (
    <div className="hero-1 -mb-12 mt-7 md:pt-0 min-h-screen grid grid-cols-1 md:grid-cols-2 justify-items-center px-10 gap-5">
      <div
        className="px-2 pt-7 my-auto mx-20 text-center md:text-left flex flex-col gap-10"
      >
        <p className="text-4xl text-white font-bold">
        KREATECH: Transforming Ideas into Innovation – Your Trusted Software Company
        </p>
        <p className="text-white text-xl">
          We believe in crafting tech solutions that not only meet your needs
          today but also set you up for success tomorrow.
        </p>
        <Link
          to="/contact"
          className="text-white w-max px-3 py-2 rounded-md font-semibold cursor-pointer m-auto md:m-0"
        >
          <button class="custom-btn btn-1 rounded-md">Get in touch</button>
        </Link>
      </div>
      <div>
        <img src={hero} alt="hero-section" />
      </div>
    </div>
  );
};

export default Home;
