import React, { useState, useEffect } from "react";
import kreatechLogo from "../logo.svg";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
const Contact = () => {
  const [activeSection, setActiveSection] = useState("");
  const [active, setActive] = useState(false);

  const navbar = [
    {
      name: "About us",
      path: "aboutUs",
    },
    {
      name: "Services",
      path: "service",
    },
    {
      name: "Case Studies",
      path: "caseStudies",
    },
    {
      name: "Blog",
      path: "blog",
    },
    {
      name: "Industry",
      path: "industry",
    },
    {
      name: "Career",
      path: "career",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        "aboutUs",
        "service",
        "caseStudies",
        "blog",
        "industry",
        "career",
        "contact",
      ];
      const currentPosition = window.scrollY + 200;
      const activeSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          return (
            currentPosition >= offsetTop &&
            currentPosition < offsetTop + offsetHeight
          );
        }
        return false;
      });

      if (activeSection) {
        setActiveSection(activeSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    setActive(false);
  };

  const contacts = [
    {
      icon: faFacebookF,
      link: "https://www.facebook.com/kreatech.ca?mibextid=ZbWKwL",
    },

    {
      icon: faInstagram,
      link: "https://www.instagram.com/kreatech_it?igshid=YzAwZjE1ZTI0Zg",
    },
    {
      icon: faLinkedinIn,
      link: "https://www.linkedin.com/company/kreatech-ca/",
    },
  ];
  return (
    <div
      id="contact"
      className="w-11/12 grid grid-cols-1 lg:grid-cols-4 gap-5 mb-12 text-left lg:justify-items-center py-7 mx-auto"
    >
      {/* First column */}
      <div className="flex flex-col">
        <img className="w-12 h-12" src={kreatechLogo} alt="Kreatech Logo" />
        <p className="px-2 text-[#718096]">
          With your strong commitment and desire to grow your business, we
          possess the necessary experience and expertise to ensure its success!
        </p>
      </div>

      
        {/* Second column */}
        <div className="flex flex-col text-[#718096]">
          <p className="text-black text-lg font-semibold mb-3">Links</p>

          <ul className="flex flex-col">
            {navbar.map((el, i) => (
              <Link
                key={i}
                to={el.path}
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                onClick={handleClick}
                className=" hover:text-[#388FE8] cursor-pointer"
              >
                {el.name}
              </Link>
            ))}

            </ul>
        </div>

        {/* Third column */}
        <div className="flex flex-col text-[#718096] mb-3">
          <p className="text-lg font-semibold text-black mb-3">Contact us</p>

          <p>Call us for a FREE Consultation Today!</p>

          <a href="tel:+8801704242711" className="mt-2 hover:text-[#388FE8]">
          <FontAwesomeIcon icon={faPhone} className="pr-2" />+8801704242711
          </a>
          <a href="https://maps.app.goo.gl/GK4CTG76RYLiAHqz9" target="_blank" className="mt-2 hover:text-[#388FE8]"><FontAwesomeIcon icon={faMapMarkerAlt} className="pr-3" />Road #4, House #1, 7th Floor, Baridhara J Block, Dhaka, 1212, Bangladesh</a>
        </div>
      

      {/* Fourth column */}
      <div className="flex flex-row gap-5 mt-auto">
        {contacts.map((el, i) => {
          return (
            <a
              key={i}
              href={el.link}
              target="_blank"
              rel="noreferrer"
              className={`w-8 h-8 shadow-lg rounded-full bg-gray-50 flex items-center justify-center hover:scale-110 hover:text-white ${
                i === 0
                  ? "hover:bg-[#4267B2]"
                  : i === 1
                  ? "hover:bg-[#E1306C]"
                  : "hover:bg-[#006AFF]"
              }  transition`}
            >
              <FontAwesomeIcon icon={el.icon} />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Contact;
