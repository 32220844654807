import React from "react";
import IndustryFirst from "./sections/IndustryFirst";
import IndustrySecond from "./sections/IndustrySecond";

const Industry = () => {
  return (
    <div
      id="industry"
      className="pt-14 min-w-full flex flex-col gap-y-4 md:gap-y-8"
    >
      <IndustryFirst />
      <IndustrySecond />
    </div>
  );
};

export default Industry;
