import React from "react";

const HalfCircle = ({
  up,
  down,
  left,
  heightSmall,
  height,
  widthSmall,
  width,
}) => {
  return (
    <div
      class={`${heightSmall ? heightSmall : "h-10"} ${
        height ? height : "md:h-20"
      } ${widthSmall ? widthSmall : "w-10"} ${
        width ? width : "md:w-20"
      } relative overflow-hidden`}
    >
      <div
        className={` w-full h-full bg-gradient-to-r from-yellow-300 to-yellow-400 rounded-full transform  ${
          up
            ? "translate-y-1/2"
            : down
            ? "-translate-y-1/2"
            : left
            ? "translate-x-1/2"
            : "-translate-x-1/2"
        }`}
      ></div>
      <div class="w-full h-full border border-black rounded-full"></div>
    </div>
  );
};

export default HalfCircle;
