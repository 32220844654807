import React from "react";

const Header = ({
  text,
  textB,
  yellow,
  blue,
  purple,
  placement,
  borderMid,
}) => {
  return (
    <div
      className={`text-2xl md:text-4xl flex gap-2 ${
        borderMid ? "items-center" : "items-start"
      }  flex-col ${placement}`}
    >
      <div
        className={`${
          yellow
            ? "from-[#F7B006] to-[#F6D2A2]"
            : blue
            ? "from-[#2777E8] to-[#6AD7E5]"
            : ""
        } bg-gradient-to-r h-1 w-16`}
      />
      <p className="">{text}</p>
      <p className="font-bold">{textB}</p>
    </div>
  );
};

export default Header;
