import React from "react";

const Footer = () => {
  return (
    <div className="text-center">
      <div className="py-5 border-t border-[#E3EEF8]">
        <p>
          © {new Date().getFullYear()} Copyright by Kreatech. All rights
          reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
