import React, { useState } from "react";
import Header from "../../components/Header";
import { techCategories } from "../../components/ThingsStandardize";

const IndustrySecond = () => {
  const [selectedCategory, setSelectedCategory] = useState("Backend");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="flex flex-col gap-10">
      <Header text="Our" textB="Tech Stack" blue borderMid />
      <div className="flex flex-col gap-5 md:w-4/5 m-auto">
        <div className="flex flex-row flex-wrap gap-5 justify-center items-center">
          {techCategories.map((el, i) => {
            return (
              <p
                key={i}
                className={`text-lg font-semibold cursor-pointer ${
                  selectedCategory === el.title
                    ? "text-[#2777E8]"
                    : "text-black"
                }`}
                onClick={() => handleCategoryClick(el.title)}
              >
                {el.title}
                <div
                  className={`h-[2px] w-10 bg-gradient-to-tr ${
                    selectedCategory === el.title
                      ? "from-[#6AD7E5] from-10% to-[#2777E8] to-90%"
                      : "bg-transparent"
                  } m-auto`}
                />
              </p>
            );
          })}
        </div>
        <div className="flex flex-row md:w-2/3 m-auto gap-3 flex-wrap justify-center">
          {techCategories
            .find((category) => category.title === selectedCategory)
            .images.map((el, i) => (
              <img key={i} src={el} alt="" className="w-32" />
            ))}
        </div>
      </div>
    </div>
  );
};

export default IndustrySecond;
