import React from "react";
import BlogFirst from "./sections/BlogFirst";

const Blog = () => {
  return (
    <div
      id="blog"
      className="pt-14 min-w-full flex flex-col gap-y-4 md:gap-y-8"
    >
      <BlogFirst />
    </div>
  );
};

export default Blog;
